import React from 'react';
import {TechseeBaseComponent} from '../_shared/techsee-base-component';
import {IDesignable} from '../_shared/reusable-props';

import './styles.scss';

export enum SpinnerTypeOptions {
    DASH = 'dash',
    CIRCULAR = 'circular'
}

export enum SpinnerSizes {
    XLarge = 'xlarge',
    Large = 'large',
    Medium = 'medium',
    Small = 'small',
    XSmall = 'xsmall'
}

export enum SpinnerBackgroundColors {
    Default = 'default',
    Transparent = 'transparent',
    White = 'white',
    Blue = 'blue',
    Green = 'green',
    Yellow = 'yellow',
    Grey = 'grey',
    LightGray = 'lightgray'
}

export enum SpinnerColors {
    Default = 'default',
    Red = 'red',
    LightBlue = 'lightblue',
    White = 'white',
    Blue = 'blue',
    Orange = 'orange',
    Green = 'green',
    Yellow = 'yellow',
    Grey = 'grey',
    LightGray = 'lightgray',
    VeryDarkGray = 'verydarkgray'
}

export type SpinnerType = SpinnerTypeOptions.DASH | SpinnerTypeOptions.CIRCULAR;

export type SpinnerSize =
    | SpinnerSizes.XLarge
    | SpinnerSizes.Large
    | SpinnerSizes.Medium
    | SpinnerSizes.Small
    | SpinnerSizes.XSmall;

export type SpinnerBackgroundColor =
    | SpinnerBackgroundColors.Default
    | SpinnerBackgroundColors.Transparent
    | SpinnerBackgroundColors.White
    | SpinnerBackgroundColors.Blue
    | SpinnerBackgroundColors.Green
    | SpinnerBackgroundColors.Yellow
    | SpinnerBackgroundColors.Grey
    | SpinnerBackgroundColors.LightGray;

export type SpinnerColor =
    | SpinnerColors.Default
    | SpinnerColors.Red
    | SpinnerColors.LightBlue
    | SpinnerColors.White
    | SpinnerColors.Blue
    | SpinnerColors.Orange
    | SpinnerColors.Green
    | SpinnerColors.Yellow
    | SpinnerColors.Grey
    | SpinnerColors.LightGray
    | SpinnerColors.VeryDarkGray;

interface ISpinner extends IDesignable {
    spinnerType?: SpinnerType;
    sizeName?: SpinnerSize;
    backgroundColorName?: SpinnerBackgroundColor;
    colorName?: SpinnerColor;
}

export class Spinner extends TechseeBaseComponent<ISpinner> {
    renderInternal() {
        this.addCssClass('icon-spinner');
        this.addCssClass(this.props.className);
        this.addCssClass('backgroundColor-' + this.props.backgroundColorName);
        this.addCssClass('size-' + this.props.sizeName);
        this.addCssClass('color-' + this.props.colorName);
        this.addCssClassIf('circular', this.props.spinnerType === SpinnerTypeOptions.CIRCULAR);

        return (
            <div className={this.renderCssClasses()}>
                <div className={'spinner-animation'} style={{width: '100%', height: '100%'}}>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        );
    }

    static defaultProps: Readonly<ISpinner> = {
        spinnerType: SpinnerTypeOptions.DASH,
        sizeName: SpinnerSizes.Small,
        backgroundColorName: SpinnerBackgroundColors.Default,
        colorName: SpinnerColors.Default
    };
}
